import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { space, width, fontSize, color, borders, borderColor, borderRadius } from 'styled-system';

const Li = styled('li', { shouldForwardProp })(
  space,
  width,
  fontSize,
  color,
  borders,
  borderColor,
  borderRadius,
  {
    boxSizing: 'border-box',
  },
);

Li.defaultProps = {
  pl: 0,
};

export default Li;
