import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { space, width, fontSize, color, borders, borderColor, borderRadius } from 'styled-system';

const Pre = styled('pre', { shouldForwardProp })(
  space,
  width,
  fontSize,
  color,
  borders,
  borderColor,
  borderRadius,
  {
    boxSizing: 'border-box',
    fontFamily: 'monaco, monospace',
  },
);

Pre.defaultProps = {};

export default Pre;
