import styled from '@emotion/styled';
import {
  space,
  width,
  height,
  maxWidth,
  position,
  top,
  left,
  right,
  bottom,
  display,
  flex,
  flexWrap,
  alignItems,
  justifyContent,
  fontFamily,
  fontSize,
  textAlign,
  color,
  borders,
  borderColor,
  borderRadius,
  boxShadow,
  gridGap,
  gridColumnGap,
  gridRowGap,
  gridColumn,
  gridRow,
  gridAutoFlow,
  gridAutoColumns,
  gridAutoRows,
  gridTemplateColumns,
  gridTemplateRows,
  gridTemplateAreas,
  gridArea,
} from 'styled-system';

export const Div = styled('div')(
  space,
  width,
  height,
  maxWidth,
  position,
  top,
  left,
  right,
  bottom,
  display,
  flex,
  flexWrap,
  alignItems,
  justifyContent,
  fontFamily,
  fontSize,
  textAlign,
  color,
  borders,
  borderColor,
  borderRadius,
  boxShadow,
  gridGap,
  gridColumnGap,
  gridRowGap,
  gridColumn,
  gridRow,
  gridAutoFlow,
  gridAutoColumns,
  gridAutoRows,
  gridTemplateColumns,
  gridTemplateRows,
  gridTemplateAreas,
  gridArea,
  {
    boxSizing: 'border-box',
  },
);

Div.defaultProps = {
  color: 'inherit',
};
