import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  space,
  width,
  fontSize,
  fontWeight,
  lineHeight,
  color,
  borders,
  borderColor,
  borderRadius,
} from 'styled-system';

const Samp = styled('samp', { shouldForwardProp })(
  space,
  width,
  fontSize,
  fontWeight,
  lineHeight,
  color,
  borders,
  borderColor,
  borderRadius,
  {
    boxSizing: 'border-box',
    fontFamily: 'monospace, monospace',
  },
);

Samp.defaultProps = {
  fontSize: 2,
};

export default Samp;
